import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [

  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'LIME',
    lpAddresses: {
      97: '',
      56: '', // EGG-BUSD LP
      28: '0xa7fD94939b50643258b947862e27Dd532677d3b0'
    },
    tokenSymbol: 'LIME',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      28 : '0xa7fD94939b50643258b947862e27Dd532677d3b0'
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

]

export default farms
